import { push } from 'connected-react-router'
import React from 'react'
import { useDispatch } from 'react-redux'
import sanity from '../util/sanity'

export default function(props) {
    const dispatch = useDispatch()
    const { sanityRef } = props
    const openPost = e => {
        e.preventDefault()
        
        sanity.fetch(`*[_id == $sanityRef].slug.current[0]`, { sanityRef }).then(slug => dispatch(push(`/news/${slug}`)))
    }
    
    return <a href="#" onClick={openPost}>{props.children}</a>
}