import { createBrowserHistory, createMemoryHistory } from 'history'
import { createStore, compose, applyMiddleware } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import createRootReducer from './reducers'

const isServer = !(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
)

export default (url = "/", initialState = {}) => {
    const history = isServer ? createMemoryHistory({initialEntries: [url]}) : createBrowserHistory()
    const composeEnhancer = isServer ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    
    const preloadedState = !isServer ? window.__PRELOADED_STATE__ : initialState
    
    if (!isServer) {
        delete window.__PRELOADED_STATE__;
    }

    const store = createStore(
        createRootReducer(history),
        preloadedState,
        composeEnhancer(
            applyMiddleware(
                routerMiddleware(history),
                thunk
            )
        )
    )
    
    return {
        store,
        history
    }
}