import {
    GET_WEATHER_DATA_STARTED,
    GET_WEATHER_DATA_SUCCESS,
    GET_WEATHER_DATA_FAILED
} from '../actionTypes'
import axios from 'axios'

export const getWeatherData = () => {
    return dispatch => {
        dispatch({
            type: GET_WEATHER_DATA_STARTED
        })

        axios.get("/api/weather")
            .then(res => {
                return dispatch({
                    type: GET_WEATHER_DATA_SUCCESS,
                    payload: res.data
                })
            })
            .catch(err => {
                return dispatch({
                    type: GET_WEATHER_DATA_FAILED,
                    payload: {
                        error: err.message
                    }
                })
            })
    }
}