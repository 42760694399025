import React from 'react'
import { Col, Pagination, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import sanity from '../util/sanity'
import imageUrlBuilder from '@sanity/image-url'
import BlockContent from './BlockContent'
import { push } from 'connected-react-router'
import NewsPostCategories from './NewsPostCategories'

const range = (start, end) => {
    if (start === end) return [start]
    return [start, ...range(start + 1, end)]
}

export default function() {
    const news = useSelector(state => {
        if (!state.cms.news) return undefined
        return state.cms.news[state.cms.news.page]
    })
    const curPage = parseInt(useSelector(state => state.router.location.query.page || 1))
    const totalPages = useSelector(state => {
        if (!state.cms.news) return undefined
        return state.cms.news.totalPages
    })
    const query = useSelector(state => state.router.location.query)
    const builder = imageUrlBuilder(sanity)
    const dispatch = useDispatch()

    return news ? (
        <div style={{paddingBottom: "1.5rem"}} >
            <h3 className="text-center">News</h3>
            {news.map(post => {
                const date = new Date(post.date)

                return (
                    <Row key={post.slug.current} >
                        <Col xs={12} sm={4} className="mt-3" onClick={() => dispatch(push("/news/" + post.slug.current))}>
                            <Row onClick={() => dispatch(push("/news/" + post.slug.current))} className="d-sm-none">
                                <Col>
                                    <h5 className="mb-0">{post.title}</h5>
                                    <p className="mb-0">{date.toDateString()}</p>
                                </Col>
                                <Col className="text-right">
                                    <p className="font-italic">by {post.author}</p>
                                </Col>
                            </Row>

                            <img src={builder.image(post.imageUrl).height(200).width(350).url()} style={{maxWidth: "575px"}} className="w-100"/>
                        </Col>
                        <Col xs={12} sm={8} className="mt-3">
                            <Row onClick={() => dispatch(push("/news/" + post.slug.current))} className="d-none d-sm-flex">
                                <Col sm={8}>
                                    <h5 className="mb-0">{post.title}</h5>
                                    <p className="mb-0">{date.toDateString()}</p>
                                </Col>
                                <Col className="text-right" sm={4}> 
                                    <p className="font-italic">by {post.author}</p>
                                </Col>
                            </Row>
                            <Row onClick={() => dispatch(push("/news/" + post.slug.current))}>
                                <Col style={{height: "100px"}} className="overflow-hidden">
                                    <BlockContent content={[post.content[0]]} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <NewsPostCategories categories={post.categories} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            })}
            <Row className="mt-2">
                <Col>
                    <Pagination className="mb-0">
                        <Pagination.Prev className="ml-auto" onClick={() => dispatch(push("/news?" + new URLSearchParams({...query, page: curPage - 1}).toString()))} disabled={curPage == 1} />
                        {range(-2,2).map(i => curPage + i).map(page => {
                            if (page >= 1 && page <= totalPages) return <Pagination.Item key={page} onClick={() => dispatch(push("/news?" + new URLSearchParams({...query, page}).toString()))} active={page == curPage} >{page}</Pagination.Item>
                        })}
                        <Pagination.Next className="mr-auto" onClick={() => dispatch(push("/news?" + new URLSearchParams({...query, page: curPage + 1}).toString()))} disabled={curPage == totalPages} />
                    </Pagination>
                </Col>
            </Row>
        </div>
    ) : ""
}