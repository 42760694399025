export const GET_CMS_STARTED = "GET_CMS_STARTED"
export const GET_CMS_SUCCESS = "GET_CMS_SUCCESS"
export const GET_CMS_FAILED = "GET_CMS_FAILED"

export const GET_WEATHER_DATA_STARTED = "GET_WEATHER_DATA_STARTED"
export const GET_WEATHER_DATA_SUCCESS = "GET_WEATHER_DATA_SUCCESS"
export const GET_WEATHER_DATA_FAILED = "GET_WEATHER_DATA_FAILED"

export const SUBMIT_CONTACT_US_FORM_STARTED = "SUBMIT_CONTACT_US_FORM_STARTED"
export const SUBMIT_CONTACT_US_FORM_SUCCESS = "SUBMIT_CONTACT_US_FORM_SUCCESS"
export const SUBMIT_CONTACT_US_FORM_FAILED = "SUBMIT_CONTACT_US_FORM_FAILED"