import React from 'react'
import { push } from "connected-react-router"
import { useDispatch } from "react-redux"
import { Button } from 'react-bootstrap'

export default function(props) {
    const dispatch = useDispatch()

    return props.categories.map(category => {
        return <Button key={category._id} variant="outline-secondary" size="sm" className="mr-1" onClick={() => dispatch(push("/news?category=" + category.slug.current))}>{category.name}</Button>
    })
}