import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { SANITY_PROJECT_ID, SANITY_DATASET } from '../util/sanity'
import Download from './Download'
import NewsPostLink from './NewsPostLink'
import { getImageUrl } from '@sanity/block-content-to-hyperscript/internals'
import { Link } from 'react-router-dom'

export default function(props) {
    const { 
        unstyledList
    } = props

    const serializers = {
        marks: {
            link: ({mark, children}) => {
                const { url } = mark
                if (url == undefined) return children
                return url.startsWith("https://") || url.startsWith("http://") ? (
                    <a href={url} target="_blank">{children}</a>
                ) : (
                    <Link to={url}>{children}</Link>
                )
            },
            post: ({mark, children}) => {
                const { reference } = mark
                const { _ref } = reference
                return <NewsPostLink sanityRef={_ref} >{children}</NewsPostLink>
            },
            download: ({mark, children}) => {
                const { reference } = mark
                const { _ref } = reference
                return <Download sanityRef={_ref} >{children}</Download>
            }
        },
        list: props => {
            const { children, type } = props
            if (unstyledList && type == "bullet") return <ul className="list-unstyled" >{children}</ul>
            return BlockContent.defaultSerializers.list(props)
        },
        types: {
            image: props => {
                return <img className="w-100" width="800px" src={getImageUrl(props)} />
            }
        }
    }

    return (
        <BlockContent projectId={SANITY_PROJECT_ID} dataset={SANITY_DATASET} blocks={props.content} imageOptions={{w: 800}} serializers={serializers} />
    )
}