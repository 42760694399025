import React from 'react'

export default function() {
    return (
        <div style={{paddingBottom: "1.5rem"}}>
            <h3 className="text-center">Club Calendar</h3>
            <div className="responsive-iframe-container d-none d-sm-block">
                <iframe src="https://calendar.google.com/calendar/b/1/embed?height=800&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Europe%2FLondon&amp;src=bmxoc2MuY28udWtfNDRxYms4cjljaHRuM2psN2xhcGJvM3FzcDhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=bmxoc2MuY28udWtfc2JrZzU4dmVwNmN0aGpuNTVpdWtjN2Y2djhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=bmxoc2MuY28udWtfbDZoNTB2aTM0ZnFybjEycGduZWNvOGZidGNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23039BE5&amp;color=%23D81B60&amp;color=%23B39DDB&amp;hl=en_GB&amp;showTabs=1&amp;showCalendars=0&amp;showTz=0&amp;showPrint=1&amp;showDate=1&amp;showNav=1&amp;showTitle=0" style={{border: "solid 1px #777"}} width="900" height="750" frameBorder="0" scrolling="no"></iframe>
            </div>
            <div className="responsive-iframe-container d-block d-sm-none" style={{height: "1200px"}}>
                <iframe src="https://calendar.google.com/calendar/b/1/embed?height=800&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Europe%2FLondon&amp;src=bmxoc2MuY28udWtfNDRxYms4cjljaHRuM2psN2xhcGJvM3FzcDhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=bmxoc2MuY28udWtfc2JrZzU4dmVwNmN0aGpuNTVpdWtjN2Y2djhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=bmxoc2MuY28udWtfbDZoNTB2aTM0ZnFybjEycGduZWNvOGZidGNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23039BE5&amp;color=%23D81B60&amp;color=%23B39DDB&amp;hl=en_GB&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0&amp;showPrint=0&amp;showDate=1&amp;showNav=1&amp;showTitle=0&amp;mode=AGENDA" style={{border: "solid 1px #777"}} width="1200" height="800" frameBorder="0" scrolling="no"></iframe>
            </div>
        </div>
    )
}