import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { submitForm } from '../redux/actions/contactUs'

const useFormFields = initialValues => {
    const [formFields, setFormFields] = React.useState(initialValues)
    const createChangeHandler = key => e => {
        const value = e.target.value
        setFormFields(prev => ({ ...prev, [key]: value }))
    }
    return { formFields, setFormFields, createChangeHandler }
}

export default function() {
    const dispatch = useDispatch()
    const categories = useSelector(state => state.cms.contactUs)
    const submitted = useSelector(state => state.contactUs.submitted)
    const { formFields, setFormFields, createChangeHandler } = useFormFields({
        name: "",
        email: "",
        categorySlug: "",
        message: ""
    })

    const submit = e => {
        e.preventDefault()
        grecaptcha.ready(() => {
            grecaptcha.execute(process.env.RAZZLE_RECAPTCHA_SITE_KEY, {action: "submit"}).then(token => {
                setFormFields({
                    name: "",
                    email: "",
                    categorySlug: "",
                    message: ""
                })
                dispatch(submitForm({token, ...formFields}))
            })
        })
    }

    return categories ? (
        <div style={{paddingBottom: "1.5rem"}} className="text-center">
            <h3>Contact Us</h3>
            {submitted ? (
                <p>Thank You. Your message has been sent and we'll be in touch shortly</p>
            ) : (
                <div>
                    <p>If you'd like more information on what we do, Membership, Training Courses or have any other queries please fill in the contact form below and we'll get back to you shortly.</p>
                    <Form className="text-left" onSubmit={submit}>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={formFields.name} onChange={createChangeHandler("name")} required={true}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" value={formFields.email} onChange={createChangeHandler("email")} required={true}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Category</Form.Label>
                            <Form.Control as="select" value={formFields.categorySlug} onChange={createChangeHandler("categorySlug")} required={true}>
                                <option value=""></option>
                                {categories.map(category => {
                                    return <option key={category.slug.current} value={category.slug.current}>{category.category}</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" value={formFields.message} onChange={createChangeHandler("message")} required={true}></Form.Control>
                        </Form.Group>                

                        <div className="text-center">
                            <Button variant="secondary" type="submit">Submit</Button>
                        </div>
                    </Form>
                </div>
            )}
        </div>
    ) : ""
}