import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getCMSPageData, getCMSSettings } from './redux/actions/cms'
import HomePage from './components/HomePage';
import MenuBar from './components/MenuBar';
import { Col, Container, Row } from 'react-bootstrap';
import Carousel from './components/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css'
import EventsPage from './components/EventsPage';
import WeatherPage from './components/WeatherPage';
import WebcamPage from './components/WebcamPage';
import FindUsPage from './components/FindUsPage';
import DownloadsPage from './components/DownloadsPage';
import NewsPage from './components/NewsPage';
import NewsPostPage from './components/NewsPostPage';
import ContactUsPage from './components/ContactUsPage';
import Footer from './components/Footer';

function App() {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const settings = useSelector(state => state.cms.settings)
  const backgroundImages = useSelector(state => state.cms.settings.backgroundImages)
  const query = useSelector(state => state.router.location.query)

  useEffect(() => {
    dispatch(getCMSSettings())
  }, [dispatch])
  
  useEffect(() => {
    dispatch(getCMSPageData(pathname, query))
  }, [dispatch, pathname, query])

  return settings ? (
    <React.Fragment>
      <Carousel backgroundImages={backgroundImages} />
      <MenuBar settings={settings} />
      <Container className="pt-4" >
        <Row>
          <Col>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/events" component={EventsPage} />
              <Route path="/weather" component={WeatherPage} />
              <Route path="/webcam" component={WebcamPage} />
              <Route path="/find_us" component={FindUsPage} />
              <Route path="/downloads" component={DownloadsPage} />
              <Route path="/news/:id" component={NewsPostPage} />
              <Route path="/news" component={NewsPage} />
              <Route path="/contact_us" component={ContactUsPage} />
            </Switch>
          </Col>
        </Row>
      </Container>
      <div style={{flex: 1}} />
      <Footer settings={settings} />
    </React.Fragment>
  ) : ""
}

export default App;
