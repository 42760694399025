import App from './app';
import React from 'react';
import { hydrate } from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from 'react-redux';
import createStore from './redux/store'
import { ConnectedRouter } from 'connected-react-router';

Sentry.init({
  dsn: process.env.RAZZLE_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
});

const { store, history } = createStore()

hydrate(
  <Provider store={store} >
    <ConnectedRouter history={history} >
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
