import React from 'react'

export default function() {
    return (
        <div style={{paddingBottom: "1.5rem"}} className="text-center">
            <h3>Find Us</h3>
            <p>
                Visitors to the Sailing Club are always Welcome however there is no guarantee anyone will be at the club outside of scheduled Training Sessions/Events, additionally as Vehicular Access to the Club is only for members or by pre-arrangement, we recommend <a href="/pages/contact_us?category=membership">Contacting Us</a> to arrange a visit and avoid disappointment.
                <br />
                <br />
                <div className="row">
                    <div className="col-sm my-auto" style={{paddingBottom: "25px"}}>
                    <span className="font-weight-bold">Club Address: </span><br />
                    <span className="fonrt-italic">North Lincolnshire and Humberside Sailing Club<br />
                    Barrow Mere<br />
                    Barton-upon-Humber<br />
                    DN18 5RB<br /></span>
                    <br />
                    <span className="font-weight-bold">Grid Reference</span>:<br/>
                    TA 0459623439<br />
                    <br />
                    <span className="font-weight-bold">Lat/Long Coordinates</span>:<br />
                    53.696921N, 0.417267W
                    </div>
                    <br />
                    <br />
                    <div className="col-sm"><iframe className="float-right" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2362.2119143113778!2d-0.41941088405818283!3d53.69666985611352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4878ead6e1a4e94f%3A0x95f04bc2d36df6fb!2sNorth%20Lincolnshire%20and%20Humberside%20sailing%20club!5e0!3m2!1sen!2suk!4v1567171166622!5m2!1sen!2suk" width="600" height="350" frameBorder="0" style={{border: 0, maxHeight: "100%", maxWidth: "100%"}} allowFullScreen=""></iframe>
                    </div>
                </div>
            </p>
        </div>
    )
}