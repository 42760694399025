import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function MenuBarItem(props) {
    return props.url.startsWith("https://") || props.url.startsWith("http://") ? (
        <Nav.Item>
            <Nav.Link href={props.url} target="_blank" className="nav-link">{props.name}</Nav.Link>
        </Nav.Item>
    ) : (
        <Nav.Item>
            <Link to={props.url} className="nav-link">{props.name}</Link>
        </Nav.Item>
    )
}