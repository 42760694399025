import React from 'react'
import { useSelector } from 'react-redux'

export default function() {
    const downloads = useSelector(state => state.cms.downloads)

    return downloads ? (
        <div style={{paddingBottom: "1.5rem"}} className="text-center">
            <h3>Downloads</h3>
            <div className="row">
                {downloads.map(category => {
                    return (
                        <div className="col-sm-4 mt-0 mb-3" key={category._id}>
                            <h5>{category.name}</h5>
                            {category.downloads.map(download => {
                                return <div key={download._id} ><a href={download.url} target="_blank">{download.name}</a><br /></div>
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    ) : ""
}