import React from 'react'
import imageUrlBuilder from '@sanity/image-url'
import sanity from '../util/sanity'
import { Row } from 'react-bootstrap'

export default function (props) {
    const { settings, style } = props
    const builder = imageUrlBuilder(sanity)

    return (
        <div style={{...style, bottom: 0, marginTop: "25px", display: "table", width: "100%"}} >
            <div style={{display: "table-cell", verticalAlign: "bottom"}} className="footer page-footer font-small grey">
                <div className="container-fluid text-center text-md-left">
                    <Row>
                        <div className="mt-1 mb-3 col-12 col-md-6 text-center text-md-left">
                            <h6>Our Accreditations</h6>
                            {settings.accreditations.map((item, i) => {
                                return <img key={i} src={builder.image(item.imageUrl).height(100).url()} height="100px" alt={item.name} className="mr-1" />
                            })}
                        </div>
                        <div className="mt-1 mb-3 col-12 col-md-6 text-center text-md-right">
                            <h6>We are Supported By</h6>
                            {settings.supporters.map((item, i) => {
                                return <img key={i} src={builder.image(item.imageUrl).height(100).url()} height="100px" alt={item.name} className="ml-1" />
                            })}
                        </div>
                    </Row>
                </div>
            </div>
      </div>
    )
}