import { LOCATION_CHANGE } from "connected-react-router"
import { SUBMIT_CONTACT_US_FORM_STARTED } from "../actionTypes"

const initialState = {
    submitted: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOCATION_CHANGE:
            return {
                ...state,
                submitted: false
            }
        case SUBMIT_CONTACT_US_FORM_STARTED:
            return {
                ...state,
                submitted: true
            }
        default:
            return state
    }
}