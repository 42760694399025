import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import cms from './cms'
import contactUs from './contactUs'
import weather from './weather'

const createRootReducer = (history) => combineReducers({
    cms,
    contactUs,
    router: connectRouter(history),
    weather
})
  
export default createRootReducer