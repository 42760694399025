import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getWeatherData } from "../redux/actions/weather";

export default function() {
    const dispatch = useDispatch()
    const weather = useSelector(state => state.weather)

    useEffect(() => {
        dispatch(getWeatherData())
    }, [dispatch])

    return (
        <div style={{paddingBottom: "1.5rem"}} className="text-center">
            <h3>Live Weather Data</h3>
            {weather.observation_time && <p>{weather.observation_time}</p>}
            {weather.observation_time && <Table hover>
                <tr>
                    <th rowSpan="5" style={{verticalAlign: "middle"}}>Wind</th>
                </tr>
                <tr>
                    <td>Current</td>
                    <td>{weather.wind.speed} knots {weather.wind.direction}</td>
                </tr>
                <tr>
                    <td>Ten Minute Average</td>
                    <td>{weather.wind.ten_min_avg} knots</td>
                </tr>
                <tr>
                    <td>Ten Minute High</td>
                    <td>{weather.wind.ten_min_gust} knots</td>
                </tr>
                <tr>
                    <td>Daily High</td>
                    <td>{weather.wind.day_high} knots at {weather.wind.day_high_time}</td>
                </tr>
                
                <tr>
                    <th rowSpan="8" style={{verticalAlign: "middle"}}>Temperature</th>
                </tr>
                <tr>
                    <td>Current</td>
                    <td>{weather.temp.current}&deg;C</td>
                </tr>
                <tr>
                    <td>Daily Low</td>
                    <td>{weather.temp.day_low}&deg;C at {weather.temp.day_low_time}</td>
                </tr>
                <tr>
                    <td>Daily High</td>
                    <td>{weather.temp.day_high}&deg;C at {weather.temp.day_high_time}</td>
                </tr>
                <tr>
                    <td>Monthly Low</td>
                    <td>{weather.temp.month_low}&deg;C</td>
                </tr>
                <tr>
                    <td>Monthly High</td>
                    <td>{weather.temp.month_high}&deg;C</td>
                </tr>
                <tr>
                    <td>Yearly Low</td>
                    <td>{weather.temp.year_low}&deg;C</td>
                </tr>
                <tr>
                    <td>Yearly High</td>
                    <td>{weather.temp.year_high}&deg;C</td>
                </tr>
                
                <tr>
                    <th rowSpan="4" style={{verticalAlign: "middle"}}>Windchill</th>
                </tr>
                <tr>
                    <td>Daily Low</td>
                    <td>{weather.windchill.day_low}&deg;C at {weather.windchill.day_low_time}</td>
                </tr>
                <tr>
                    <td>Monthly Low</td>
                    <td>{weather.windchill.month_low}&deg;C</td>
                </tr>
                <tr>
                    <td>Yearly Low</td>
                    <td>{weather.windchill.year_low}&deg;C</td>
                </tr>
                
                <tr>
                    <th rowSpan="4" style={{verticalAlign: "middle"}}>Heat Index</th>
                </tr>
                <tr>
                    <td>Daily High</td>
                    <td>{weather.heatIndex.day_high}&deg;C at {weather.heatIndex.day_time_time}</td>
                </tr>
                <tr>
                    <td>Monthly High</td>
                    <td>{weather.heatIndex.month_high}&deg;C</td>
                </tr>
                <tr>
                    <td>Yearly High</td>
                    <td>{weather.heatIndex.year_high}&deg;C</td>
                </tr>
                
                <tr>
                    <th rowSpan="2" style={{verticalAlign: "middle"}}>Humidity</th>
                </tr>
                <tr>
                    <td>Current</td>
                    <td>{weather.humidity.current}&#37;</td>
                </tr>
                
                <tr>
                    <th rowSpan="4" style={{verticalAlign: "middle"}}>Rain</th>
                </tr>
                <tr>
                    <td>Daily</td>
                    <td>{weather.rain.day} mm</td>
                </tr>
                <tr>
                    <td>Monthly Total</td>
                    <td>{weather.rain.month} mm</td>
                </tr>
                <tr>
                    <td>Yearly Total</td>
                    <td>{weather.rain.year} mm</td>
                </tr>
                
                <tr>
                    <th rowSpan="3" style={{verticalAlign: "middle"}}>Astronomy</th>
                </tr>
                <tr>
                    <td>Sunrise</td>
                    <td>{weather.astronomy.sunrise}</td>
                </tr>
                <tr>
                    <td>Sunset</td>
                    <td>{weather.astronomy.sunset}</td>
                </tr>
                
                <tr>
                    <th rowSpan="3" style={{verticalAlign: "middle"}}>Barometer</th>
                </tr>
                <tr>
                    <td>Current</td>
                    <td>{weather.barometer.current} mb</td>
                </tr>
                <tr>
                    <td>Trend</td>
                    <td>{weather.barometer.trend}</td>
                </tr>
            </Table>}
        </div>
    )
}