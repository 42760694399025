import React from 'react'
import { useSelector } from 'react-redux'
import sanity from '../util/sanity'
import imageUrlBuilder from '@sanity/image-url'
import BlockContent from './BlockContent'
import NewsPostCategories from './NewsPostCategories'

export default function(props) {
    const post = useSelector(state => (state.cms.newsPost || {})[props.match.params.id])
    const builder = imageUrlBuilder(sanity)

    return post ? (
        <div style={{paddingBottom: "1.5rem"}}>
            <h3 className="text-center mb-0">{post.title}</h3>
            <p className="text-center mb-1 w-100">
                {new Date(post.date).toDateString()}&nbsp;
                <span className="font-italic">by {post.author}</span>
            </p>
            <img src={builder.image(post.imageUrl).height(410).width(600).url()} className="float-left mr-3 mb-2 w-100" style={{maxWidth: "600px"}} />
            <BlockContent content={post.content} />
            <NewsPostCategories categories={post.categories} />
        </div>
    ) : ""
}