import React from 'react'

export default function() {
    return (
        <div style={{paddingBottom: "1.5rem"}} className="text-center">
            <h3>Club Webcam</h3>
            <p>A Live Video Stream from the Club Webcam is displayed below</p>
            <img style={{height: "auto", maxWidth: "100%", WebkitUserSelect: "none", margin: "auto"}} src="https://webcam.nlhsc.co.uk"></img>
        </div>
    )
}