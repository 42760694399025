import {
    SUBMIT_CONTACT_US_FORM_FAILED,
    SUBMIT_CONTACT_US_FORM_STARTED
} from '../actionTypes'
import axios from 'axios'

export const submitForm = data => {
    return dispatch => {
        dispatch({
            type: SUBMIT_CONTACT_US_FORM_STARTED,
            data
        })

        axios.post("/api/contact_us", data)
            .then(() => {
                return dispatch({
                    type: SUBMIT_CONTACT_US_FORM_SUCCESS
                })
            })
            .catch(err => {
                return dispatch({
                    type: SUBMIT_CONTACT_US_FORM_FAILED,
                    payload: {
                        error: err.message
                    }
                })
            })
    }
}