import { GET_WEATHER_DATA_SUCCESS } from "../actionTypes"

export default function (state = {}, action) {
    switch (action.type) {
        case GET_WEATHER_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}