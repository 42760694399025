import {
    GET_CMS_STARTED,
    GET_CMS_SUCCESS,
    GET_CMS_FAILED
} from '../actionTypes'
import sanity from '../../util/sanity'
import { match } from 'path-to-regexp'

const getCMS = (key, fetchCMS, params = []) => {
    return dispatch => {
        dispatch({
            type: GET_CMS_STARTED,
            key
        })

        fetchCMS(...params)
            .then(payload => {
                dispatch({
                    type: GET_CMS_SUCCESS,
                    key,
                    payload
                })
            })
            .catch(err => {
                dispatch({
                    type: GET_CMS_FAILED,
                    key,
                    payload: {
                        error: err.message
                    }
                })
            })
    }
}

export const getCMSSettings = () => getCMS("settings", fetchCMSSettings)

export const fetchCMSSettings = async () => {
    const query = `*[_type == "settings" && _id == "settings"] {
        title,
        menu[] {
            _key,
            _type,
            url,
            name,
            subMenuItem[] {
                _key,
                name,
                url
            }
        },
        "backgroundImages": backgroundImages[] {
            _key,
            "imageUrl": asset->url
        },
        "logo": logo.asset->url,
        accreditations[] {
            name,
            "imageUrl": logo.asset->url
        },
        supporters[] {
            name,
            "imageUrl": logo.asset->url
        }
      }[0]`

    return sanity.fetch(query)
}

const fetchCMSHomePage = async () => {
    const query = `*[_type == "homePage" && _id == "homePage"] {
        "homePageSections": homePageSection,
        "newsPosts": *[_type == 'post' && date <= $today] {
            slug,
            'author': author->name,
            'categories': categories[]->{
                _id,
                name,
                slug
            },
            content,
            date,
            'imageUrl': image.asset->url,
            title
        } | order(date desc)[0...2]
    }[0]`

    const params = {
        today: new Date().toISOString()
    }

    return sanity.fetch(query, params)
}

const fetchCMSDownloads = async () => {
    const query = `*[_type == 'downloadCategory' && visible == true] {
        _id,
        name,
        'downloads': *[_type == 'download' && references(^._id)] {
            _id,
            name,
            'url': file.asset->url
        } | order(name)
    } | order(name)`

    return sanity.fetch(query)
}

const fetchCMSNews = async (routeParams, queryParams) => {
    const query = `{
        "posts": *[_type == 'post' && date <= $today && categories[]->slug.current match $category] {
            slug,
            'author': author->name,
            'categories': categories[]->{
                _id,
                name,
                slug
            },
            content,
            date,
            'imageUrl': image.asset->url,
            title
        } | order(date desc)[($pageSize * ($page - 1))...($page * $pageSize)],
        "totalPages": select(
            count(*[_type == 'post' && date <= $today && categories[]->slug.current match $category]) < $pageSize => 1,
            round((count(*[_type == 'post' && date <= $today && categories[]->slug.current match $category]) - (count(*[_type == 'post' && date <= $today && categories[]->slug.current match $category]) % $pageSize)) / $pageSize)
        ),
        "page": $page
    }`

    const params = {
        page: parseInt(queryParams.page || 1),
        today: new Date().toISOString(),
        category: queryParams.category || "*",
        pageSize: 25
    }

    return sanity.fetch(query, params)
}

const fetchCMSNewsPost = async (routeParams, queryParams) => {
    const query = `{
        $slug: *[_type == 'post' && slug.current == $slug] {
            'author': author->name,
            'categories': categories[]->{
                _id,
                name,
                slug
            },
            content,
            date,
            'imageUrl': image.asset->url,
            title
        }[0]
    }`

    const params = {
        slug: routeParams.slug
    }

    return sanity.fetch(query, params)
}

const fetchCMSContactUs = () => {
    const query = `*[_type == "contactCategories"] {
        category,
        slug
    }`

    return sanity.fetch(query)
}

const routes = {
    "/": {
        key: "homePage",
        query: fetchCMSHomePage
    },
    "/downloads": {
        key: "downloads",
        query: fetchCMSDownloads
    },
    "/news/:slug": {
        key: "newsPost",
        query: fetchCMSNewsPost
    },
    "/news": {
        key: "news",
        query: fetchCMSNews
    },
    "/contact_us": {
        key: "contactUs",
        query: fetchCMSContactUs
    }
}

const matchCMSPagePath = curPath => {
    for (let i = 0; i < Object.keys(routes).length; i++) {
        const route = Object.keys(routes)[i]
        const result = match(route)(curPath)
        
        if (result) {
            const { params } = result
            const { key, query } = routes[route]

            return {
                key,
                query,
                routeParams: params
            }
        }
    }
}

export const getCMSPageData = (curPath, queryParams) => {
    const pageData = matchCMSPagePath(curPath)
    if (!pageData) return () => { }

    const { key, query, routeParams } = pageData
    return getCMS(key, query, [routeParams, queryParams])
}

export const fetchCMSPageData = (curPath, queryParams) => {
    const pageData = matchCMSPagePath(curPath)
    if (!pageData) return

    const { key, query, routeParams } = pageData
    return query(routeParams, queryParams).then(data => {
        if (key == "news") return {
            news: {
                [data.page]: data.posts,
                page: data.page,
                totalPages: data.totalPages
            }
        }

        return {
            [key]: data
        }
    })
}

