import React from 'react'
import { push } from "connected-react-router"
import { useDispatch } from "react-redux"
import sanity from "../util/sanity"
import imageUrlBuilder from '@sanity/image-url'
import { Col, Row } from 'react-bootstrap'
import BlockContent from './BlockContent'
import NewsPostCategories from './NewsPostCategories'

export default function(props) {
    const dispatch = useDispatch()
    const builder = imageUrlBuilder(sanity)
    const date = new Date(props.date)

    return (
        <Row>
            <Col xs={12} sm={5} className="mt-3" onClick={() => dispatch(push("/news/" + props.slug.current))}>
                <Row onClick={() => dispatch(push("/news/" + props.slug.current))} className="d-sm-none">
                    <Col>
                        <h5 className="mb-0">{props.title}</h5>
                        <p className="mb-0">{date.toDateString()}</p>
                    </Col>
                    <Col className="text-right">
                        <p className="font-italic">by {props.author}</p>
                    </Col>
                </Row>
                
                <img src={builder.image(props.imageUrl).height(200).width(350).url()} style={{maxWidth: "575px"}} className="w-100"/>
            </Col>
            <Col xs={12} sm={7} className="mt-3">
                <Row onClick={() => dispatch(push("/news/" + props.slug.current))} className="d-none d-sm-flex">
                    <Col sm={8}>
                        <h5 className="mb-0">{props.title}</h5>
                        <p className="mb-0">{date.toDateString()}</p>
                    </Col>
                    <Col className="text-right" sm={4}> 
                        <p className="font-italic">by {props.author}</p>
                    </Col>
                </Row>
                <Row onClick={() => dispatch(push("/news/" + props.slug.current))}>
                    <Col style={{height: "100px"}} className="overflow-hidden">
                        <BlockContent content={[props.content[0]]} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}