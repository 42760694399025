import React from 'react'
import { Carousel } from 'react-bootstrap'

export default function(props) {
    const { backgroundImages } = props

    return (
        <div id="background-carousel">
            <Carousel controls={false} indicators={false} >
                {backgroundImages.map(image => {
                    return (
                        <Carousel.Item key={image._key} style={{backgroundImage: "url('" + image.imageUrl + "')"}} />
                    )
                })}
            </Carousel>
        </div>
    )
}