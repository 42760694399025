import React from 'react'
import { NavDropdown } from "react-bootstrap"
import { Link } from "react-router-dom"

export default function(props) {
    return (
        <NavDropdown title={props.name} >
            {props.items.map(subMenuItem => {
                return subMenuItem.url.startsWith("https://") || subMenuItem.url.startsWith("http://") ? (
                    <NavDropdown.Item key={subMenuItem._key} href={subMenuItem.url} target="_new">{subMenuItem.name}</NavDropdown.Item>
                ) : (
                    <Link key={subMenuItem._key} to={subMenuItem.url} className="dropdown-item">
                        <NavDropdown.Item as="div" href="#" bsPrefix="no-bs-prefix" active={false} >{subMenuItem.name}</NavDropdown.Item>
                    </Link>
                )
            })}
        </NavDropdown>
    )
}