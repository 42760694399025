import React from "react"
import { Nav, Navbar } from "react-bootstrap"
import sanity from "../util/sanity";
import MenuBarItem from "./MenuBarItem";
import MenuBarSubMenu from './MenuBarSubMenu'
import imageUrlBuilder from '@sanity/image-url'

export default function(props) {
    const { settings } = props;
    const builder = imageUrlBuilder(sanity)
    
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand className="text-wrap">{settings.title}</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
                <Nav className="mr-auto" >
                    {settings.menu.map(menuItem => {
                        switch (menuItem._type) {
                            case "menuItem":
                                return <MenuBarItem key={menuItem._key} url={menuItem.url} name={menuItem.name} />
                            case "subMenu":
                                return <MenuBarSubMenu key={menuItem._key} name={menuItem.name} items={menuItem.subMenuItem} />
                        }
                    })}
                </Nav>
                <img src={builder.image(settings.logo).height(50).url()} height="50px" className="d-block d-lg-none" />
            </Navbar.Collapse>
            <img src={builder.image(settings.logo).height(50).url()} height="50px" className="d-none d-lg-block" />
        </Navbar>
    )
}