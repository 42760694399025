import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import BlockContent from './BlockContent'
import HomePageNewsPost from "./HomePageNewsPost";

export default function() {
    const { homePageSections, newsPosts } = useSelector(state => state.cms.homePage) || {}

    return homePageSections ? (
        <React.Fragment>
            <div className="text-center" >
                <h3>Latest News</h3>
            </div>
            <Row className="mb-3" >
                {newsPosts.map((newsPost, i) => {
                    return (
                        <Col key={i} lg={12} xl={6} >
                            <HomePageNewsPost {...newsPost} />
                        </Col>
                    )
                })}
            </Row>
            {homePageSections.map(section => {
                if (!section.published) return
                return (
                    <div key={section._key} className="text-center" >
                        <h3 className={section.important ? "text-danger" : ""} >{section.title}</h3>
                        <BlockContent content={section.content} unstyledList={true} />
                    </div>
                )
            })}
        </React.Fragment>
    ) : ""
}