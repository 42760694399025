import React from 'react'
import sanity from '../util/sanity'

export default function(props) {
    const { sanityRef } = props
    const openDownload = e => {
        e.preventDefault()
        
        sanity.fetch(`*[_id == $sanityRef] {
            "url": file.asset->url
        }.url[0]`, { sanityRef }).then(url => window.open(url, "_blank"))
    }

    return <a href="#" onClick={openDownload}>{props.children}</a>
}