import { GET_CMS_SUCCESS } from "../actionTypes"

export default function (state = {}, action) {
    switch (action.type) {
        case GET_CMS_SUCCESS:
            switch (action.key) {
                case "newsPost":
                    return {
                        ...state,
                        [action.key]: {
                            ...state[action.key],
                            ...action.payload
                        }
                    }
                case "news":
                    return {
                        ...state,
                        news: {
                            ...state.news,
                            [action.payload.page]: action.payload.posts,
                            page: action.payload.page,
                            totalPages: action.payload.totalPages
                        }
                    }
                default:
                    return {
                        ...state,
                        [action.key]: action.payload
                    }
            }
        default:
            return state
    }
}